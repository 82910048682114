<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          name: "To-do List",
          icon: "PROSmart-Todo",
        },
      ],
      todoList: [],
      tenderInfoMap: {},
      keyword: "",
    };
  },
  created() {
    this.$router.push({
      name: "todo",
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "TodoList" && from.name !== "TodoList") {
      this.$router.push({
        name: "todo",
      });
      return;
    }
    next();
  },
};
</script>
